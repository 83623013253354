import BaseFilter from "../filters/baseFilter";
import BlogsFilter from "../filters/blogsFilter";
import Pagination from '../utils/pagination';
import debounce from 'lodash.debounce';
import Select from "../utils/select";
$ = jQuery;

class PostScrollListSection {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;
        this.root = el;
        this.list = this.root.querySelector('[data-post-scroll-list-wrapper]');
        this.baseFilterPostWrapper = this.root.querySelector('.js-posts-filters-wrapper');
        this.filter = this.root.querySelector('[data-base-filter]');
        this.blogFilter = this.root.querySelector('[data-blog-filter]');
        this.paginationEl = this.root.querySelector('[data-pagination]');
        this.loader = this.root.querySelector('.loader');
        this.powerOfFiltersDiv = this.root.querySelector(".posts-scroll-list__panels-filter");
        this.filterInstance = null;
        this.blogsFilterInstance = null;
        this.paginationInstance = null;
        this.powerOfPanelsInstance = null;
        this.powerOfPanelsInstanceValue = null;
        this.isPaginationMounted = false;

        this.activeOptionEl = this.root.querySelector(".base-filter__option.active");
        this.activeOption = this.activeOptionEl && this.activeOptionEl.getAttribute("data-value") !== "all" ? this.activeOptionEl.getAttribute("data-value") : null ;
        this.page = 1;
        this.html = '';
        this.settings = JSON.parse(this.root.getAttribute('data-settings'));

        this.getPosts = debounce(this.loadPosts, 100);

        this.filterChanged = false;

        return true;
    }

    init() {
        this.initFilter();
        this.initBlogFilter();
        this.initPagination();
        this.initPowerOfInstance();
        this.setHandleUrl("blog", "data-blog-id");
    }

    setHandleUrl(keyToSearch, dataAttribute) {
        const url = new URLSearchParams(window.location.search);
        if (url.size === 0) return;
        for (const [key, value] of url) {
            if (key === keyToSearch && value != 1) {
                const searchedTab = this.root.querySelector(`[${dataAttribute}="${value}"]`);
                if (searchedTab) {
                    searchedTab.click();
                }
            }
        }
    }

    setBrowserUrl(url) {
        window.history.pushState({},"", url);
    }

    initBlogFilter() {
        if (!this.blogFilter) return;
        this.blogsFilterInstance = new BlogsFilter(this.blogFilter, this.root);
        this.root.addEventListener('blog-filter-change', event => {
            this.onBlogFilterChange(event);
            const {detail} = event;
            const blogTab = this.root.querySelector(`[data-blog-id="${detail}"]`);
            const browserUrl = blogTab.getAttribute("data-browser-url");
            if (!browserUrl.includes("?")) {
                this.setBrowserUrl(browserUrl);
            } else {
                if (browserUrl?.length > 0 && !window.location.href.includes(browserUrl)) this.setBrowserUrl(browserUrl);
            }
        });
    }

    initFilter() {
        if (!this.filter) return;
        this.filterInstance = new BaseFilter(this.filter, this.root);
        this.root.addEventListener('base-filter-change', event => {
            this.onBaseFilterChange(event);
        });
    }

    initPagination() {
        if (!this.paginationEl) return;
        this.paginationInstance = new Pagination(this.paginationEl, this.root);
        this.root.addEventListener('pagination-change', event => this.onPaginationChange(event));
        this.isPaginationMounted = true;
    }

    initPowerOfInstance() {
        if (!this.powerOfFiltersDiv) return;
        this.powerOfPanelsInstance = new Select(this.powerOfFiltersDiv.querySelector(".custom-select"), this.root);

        this.root.addEventListener("custom-select-change", (e) => {
            const {detail} = e;
            if (detail === "all") {
                this.powerOfPanelsInstanceValue = null;
            } else {
                this.powerOfPanelsInstanceValue = parseInt(detail);
            }
            this.page = 1;
            this.getPosts();
        })
    }

    onPaginationChange(event) {
        const { detail } = event;
        this.page = detail;
        this.loader.classList.add('active');
        this.getPosts();
    }

    onBaseFilterChange(event) {
        this.page = 1;
        const { detail } = event;
        this.loader.classList.add('active');
        const clickedElement = document.querySelector(`[data-value="${detail}"]`);
        console.log(detail);
        if (detail === 'all') {
            this.activeOption = null;
        } else {
            this.activeOption = detail;
        }
        
        this.filterChanged = true;
        this.powerOfPanelsInstanceValue = null;

        if (this.powerOfPanelsInstance) {
            const {items} = this.powerOfPanelsInstance;
            items[0].click();
        }

        
        if (clickedElement.tagName.toLowerCase() === "a") {
            return true;
        } else {
            this.getPosts();

        }
    }

    onBlogFilterChange(event) {
        const formData = new FormData();

        this.settings.blog_id = parseInt(event.detail);

        formData.append('action', 'load_posts_filters');
        formData.append('blog_id', this.settings.blog_id);
        formData.append('card_type', this.settings.card_type);
        formData.append('post_type', this.settings.post_type);
        formData.append('per_page', this.settings.per_page);
        formData.append('taxonomy_name', this.settings.taxonomy_name);

        this.loader.classList.add('active');
        this.filterInstance = null;

        $.ajax({
            type: 'post',
            url: ajax.url,
            enctype: 'multipart/form-data',
            processData: false,
            contentType: false,
            data: formData,
            context: this,
            beforeSend: function () {
            },
            success: function(response) {
                this.loader.classList.remove('active');

                const data = JSON.parse(response);

                const { html, max_pages, is_init_power_of_panels} = data;

                this.baseFilterPostWrapper.innerHTML = html;

                this.filter = this.root.querySelector('[data-base-filter]');
                this.list = this.root.querySelector('[data-post-scroll-list-wrapper]');
                this.paginationEl = this.root.querySelector('[data-pagination]');

                this.filterInstance = new BaseFilter(this.filter, this.root);
                this.root.addEventListener('base-filter-change', event => {
                    this.onBaseFilterChange(event)
                    const {detail} = event;
                    const catTab = this.root.querySelector(`[data-value="${detail}"]`);
                    const browserUrl = catTab.getAttribute("data-browser-url");
                    if (browserUrl?.length > 0) this.setBrowserUrl(browserUrl)
                    
                    this.setHandleUrl("cat", "data-value");
                });
                this.setHandleUrl("cat", "data-value");
                this.paginationInstance = new Pagination(this.paginationEl, this.root);

                if (is_init_power_of_panels) {
                    this.powerOfFiltersDiv = this.root.querySelector(".posts-scroll-list__panels-filter");
                    this.initPowerOfInstance();
                }
                
            },
            complete: function() {
                this.root.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        })
    }

    loadPosts() {
        const formData = new FormData();

        formData.append('action', 'load_scroll_posts');
        formData.append('page', this.page);
        formData.append('taxonomy', this.settings.taxonomy_name);
        formData.append('per_page', this.settings.per_page);
        formData.append('post_type', this.settings.post_type);
        formData.append('card_type', this.settings.card_type);
        formData.append('blog_id', this.settings.blog_id);

        if (this.powerOfFiltersDiv && this.powerOfFiltersDiv.classList.contains("show") && this.powerOfPanelsInstanceValue){
            formData.append("power_of_panels", this.powerOfPanelsInstanceValue)
        }
        if (this.activeOption) formData.append('category', this.activeOption);

        $.ajax({
            type: 'post',
            url: ajax.url,
            enctype: 'multipart/form-data',
            processData: false,
            contentType: false,
            data: formData,
            context: this,
            beforeSend: function () {
            },
            success: function (response) {
                this.loader.classList.remove('active');

                const data = JSON.parse(response);
                const { html, max_pages, show_panels_filter } = data;

                this.settings.max_pages = max_pages;
                this.list.innerHTML = html;

                if (!this.isPaginationMounted) {
                    this.initPagination();
                } 

                this.paginationInstance.reset(this.page, max_pages);

                this.filterChanged = false;
                
               if (this.powerOfFiltersDiv) {
                if (show_panels_filter) {
                    this.powerOfFiltersDiv.classList.add("show");
                } else {
                    this.powerOfFiltersDiv.classList.remove("show");
                }
               }
            },
            complete: function() {
                this.root.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        });
    }
}

export default function postScrollList() {
    const sections = document.querySelectorAll('[data-post-scroll-list]');
    if (!sections) return;

    sections.forEach(item => {
        new PostScrollListSection(item);
    })
}