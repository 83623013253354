// import headerBio from './headerBio';
import showContactBtn from './headerContactBtnHandler';
import headerContactShowHandler from './headerContactShowHandler';
import headerScrollHander from './headerScrollHander';
import headerTopHandler from './headerTopHandler';
import headerSearch from './headerSearch';
import searchPagination from '../search/searchPagination';
export default function header() {
    headerSearch(); 
    if (document.getElementById('searchPagination')) { 
        searchPagination();
    }
    const openMenuButton = document.querySelector('.js-open-menu');
    const closeMenuButton = document.querySelector('.js-close-menu');
    const headerSide = document.querySelector('.header-side');
    const headerMenu = document.querySelector('.header-menu');
    const headerTopRightElement = document.querySelector(
        '.header-top__right-wrapper'
    );
    const headerTop = document.querySelector('.header-top');
    const headerMenuItem = document.querySelectorAll('.js-header-menu-item');
    const headerCopyrightText = document.querySelector(
        '.header-menu__copyright-text'
    );
    const headerContactBtn = document.querySelector('.js-show-form');
    const headerBackgroundColor = document.querySelector(
        '.js-header-background-color'
    );
    const headerBackgroundImage = document.querySelector(
        '.js-header-background-image'
    );
    const headerBackgroundImageMask = document.querySelector(
        '.header-menu__background-image-mask'
    );
    const headerDescriptionLink = document.querySelector(
        '.header-menu__description-link-wrapper'
    );
    const headerDescription = document.querySelector('.js-header-description');
    const menuItemsSocialsWrapper = document.querySelector(
        '.header-menu__items-socials-wrapper'
    );
    const hamburgerWrapper = document.querySelector(
        '.header-side__open-menu-button'
    );
    const secondItemsWrapper = document.querySelector(
        '.header-menu__second-items-wrapper'
    );
    const logoWrapper = document.querySelector('.header-menu__logo-wrapper');
    const secondColumnButtonWrapper = document.querySelector(
        '.header-menu__button-wrapper'
    );
    const backgroundImageWrapper = document.querySelector(
        '.header-menu__background-image-wrapper'
    );
    const linesWrapper = document.querySelector('.lines');

    const animationsElementsArray = [
        {
            element: headerBackgroundImageMask,
            firstClass: 'js-clip-bg-right',
            secondClass: 'js-unclip-bg-right',
            hideClass: 'active',
            lastClass: 'js-clip-bg-right',
            isOnlyDesktop: false,
        },
        {
            element: headerDescriptionLink,
            firstClass: 'js-fade-out',
            secondClass: 'js-fade-in-right',
            hideClass: 'hide',
            lastClass: 'js-fade-out-right',
            isOnlyDesktop: false,
        },
        {
            element: menuItemsSocialsWrapper,
            firstClass: 'js-fade-out',
            secondClass: 'js-fade-in-right',
            hideClass: 'hide',
            lastClass: 'js-fade-out-right',
            isOnlyDesktop: false,
        },
        {
            element: secondItemsWrapper,
            firstClass: 'js-fade-out',
            secondClass: 'js-fade-in-right--desktop',
            hideClass: 'hide',
            lastClass: 'js-fade-out-right--desktop',
            isOnlyDesktop: true,
        },
        {
            element: logoWrapper,
            firstClass: 'js-fade-out',
            secondClass: 'js-fade-in-left',
            hideClass: 'hide',
            lastClass: 'js-fade-out-left',
            isOnlyDesktop: true,
        },
        {
            element: secondColumnButtonWrapper,
            firstClass: 'js-fade-out',
            secondClass: 'js-fade-in-right',
            hideClass: 'hide',
            lastClass: 'js-fade-out-right',
            isOnlyDesktop: true,
        },
    ];    
    // Funkcja odpowiadająca za wyszukanie klasy rozpoczynającej się z bg oraz usunięcie jej (na rzecz kolejnej klasy z bg)

    const removeBgColorClass = el => {
        const classesArray = Array.from(el.classList);
        const bgClasses = classesArray.filter(className =>
            className.startsWith('bg')
        );
        return el.classList.remove(...bgClasses);
    };

    // Funkcja odpowiadająca za otwieranie menu

    const openMenu = () => {
        animationsHandler(animationsElementsArray, 'hideClass');
        openMenuButton.classList.add('hide');
        headerSide.classList.add('active');
        headerMenu.classList.add('opened');
        headerTop.classList.remove('fade-out');
        headerTop.classList.add('active');
        closeMenuButton.classList.add('blocked');
        // document.documentElement.classList.add('no-scroll');
        if (headerTopRightElement) {
            headerTopRightElement.classList.add('js-fade-out');
        }
        setTimeout(() => {
            headerCopyrightText.classList.remove('js-fade-out-right');
            headerCopyrightText.classList.add('js-fade-in-right');
            linesWrapper.classList.add('active');
        }, 300);
        setTimeout(() => {
            closeMenuButton.classList.remove('blocked');
            headerBackgroundColor.classList.add('mobile-animation');
            backgroundImageWrapper.classList.add('mobile-animation');
        }, 900);
    };

    // Funkcja odpowiedająca za zamykanie menu

    const closeMenu = () => {
        animationsHandler(animationsElementsArray, 'lastClass');
        headerBackgroundColor.classList.remove('mobile-animation');
        backgroundImageWrapper.classList.remove('mobile-animation');
        headerCopyrightText.classList.remove('js-fade-in-right');
        headerCopyrightText.classList.add('js-fade-out-right');

        if (window.scrollY > 120) {
            headerTop.classList.add('fade-out');
        } else {
            headerTop.classList.remove('fade-out');
        }
        setTimeout(() => {
            openMenuButton.classList.remove('hide');
            headerSide.classList.remove('active');
            headerTop.classList.remove('active');
            headerMenu.classList.remove('opened');
            linesWrapper.classList.remove('active');
            if (headerTopRightElement)
                headerTopRightElement.classList.remove('js-fade-out');
            // document.documentElement.classList.remove('no-scroll');
        }, 300);
    };

    // Funkcja odpowiadająca za animacje elementów w trakcie przełączania pozycji w menu, przekazywany obiekt to argument z elementami oraz ich klasami, drugim argumentem jest klucz z  wejściową klasa

    const animationsHandler = (elements, selectedClass) => {
        const screenWidth = window.innerWidth;
        if (selectedClass === 'lastClass') {
            elements.forEach(element => {
                if (!element.element) return;
                element.element.classList.add(element[selectedClass]);
            });
            setTimeout(() => {
                elements.forEach(element => {
                    if (!element.element) return;
                    element.element.classList.remove(element[selectedClass]);
                });
            }, 1000);
        } else {
            elements.forEach(element => {
                if (!element.element) return;
                if (element.isOnlyDesktop && screenWidth > 992) {
                    element.element.classList.add(element[selectedClass]);
                } else if (!element.isOnlyDesktop) {
                    element.element.classList.add(element[selectedClass]);
                }
            });
            setTimeout(() => {
                elements.forEach(element => {
                    if (!element.element) return;
                    if (element.isOnlyDesktop && screenWidth > 992) {
                        element.element.classList.add(element.secondClass);
                    } else if (!element.isOnlyDesktop) {
                        element.element.classList.add(element.secondClass);
                    }
                });
            }, 310);

            setTimeout(() => {
                elements.forEach(element => {
                    if (!element.element) return;
                    if (element.isOnlyDesktop && screenWidth > 992) {
                        element.element.classList.remove(
                            element[selectedClass]
                        );
                        element.element.classList.remove(element.secondClass);
                    } else if (!element.isOnlyDesktop) {
                        element.element.classList.remove(
                            element[selectedClass]
                        );
                        element.element.classList.remove(element.secondClass);
                    }
                });
            }, 1100);
        }
    };

    // Funkcja odpowiadająca za stworzenie menu elementów w drugim poziomie

    const createSecondMenu = (data, activeColor, activePage) => {
        secondItemsWrapper.textContent = '';

        data.forEach(secondItem => {
            const li = document.createElement('li');
            const a = document.createElement('a');
            const url = secondItem.element.url ? secondItem.element.url : '#';
            a.textContent = secondItem.element.title;
            a.href = url;
            if (
                secondItem.element.target &&
                secondItem.element.target.length > 0
            ) {
                a.setAttribute('target', '_blank');
            }
            a.classList.add(
                'link-regular',
                'button',
                'button--menu-item',
                'f-500',
                `${activeColor}`
            );
            if (url === activePage) {
                a.classList.add('active');
            }
            li.appendChild(a);
            secondItemsWrapper.appendChild(li);
        });
    };

    const createSecondColumnButton = (data, activeColor, knowMoreText) => {
        if (secondColumnButtonWrapper.querySelector('a')) {
            secondColumnButtonWrapper.removeChild(
                secondColumnButtonWrapper.querySelector('a')
            );
        }
        if (data.url && data.url.length > 0) {
            const a = document.createElement('a');
            a.href = data.url;
            a.classList.add(
                'header-menu__button',
                'button',
                'link-regular',
                'f-500',
                'button--outline',
                `${activeColor}`
            );
            const span = document.createElement('span');
            span.textContent = data.title ? data.title : knowMoreText;
            a.appendChild(span);
            if (data.target && data.target.length > 0) {
                a.setAttribute('target', '_blank');
            }
            secondColumnButtonWrapper.appendChild(a);
        }
    };

    // Funkcja odpowiadająca za stworzenie przycisku w trzeciej kolumnie

    const createThirdColumnLink = (data, activeColor, knowMoreText) => {
        if (headerDescriptionLink && headerDescriptionLink.querySelector('a')) {
            headerDescriptionLink.removeChild(
                headerDescriptionLink.querySelector('a')
            );
        }
        if (data.url && data.url.length > 0) {
            const a = document.createElement('a');
            a.href = data.url;
            a.classList.add(
                'header-menu__third-column-link',
                'button',
                'link-regular',
                'button--menu-item',
                'f-500',
                `${activeColor}`
            );
            a.textContent = data.title ? data.title : knowMoreText;
            if (data.target && data.target.length > 0) {
                a.setAttribute('target', '_blank');
            }
            
            if (headerDescription) headerDescription.appendChild(a);
        }
    };

    // Funkcja odpowiadająca za podmiane contentu

    const changeContent = (element, activeColor) => {
        headerMenu.classList.add("-blocked");
        const activeImageUrl = element.dataset.bgimage
            ? element.dataset.bgimage
            : '';
        const activeDescription = element.dataset.description
            ? element.dataset.description
            : '';
        const activeSecondMenuData = element.dataset.seconditems
            ? JSON.parse(element.dataset.seconditems)
            : [];
        const activeLogo = element.dataset.logo ? element.dataset.logo : '';
        const activeThirdColumnLink = element.dataset.thirdcolumnlink
            ? JSON.parse(element.dataset.thirdcolumnlink)
            : [];
        const knowMoreText = element.dataset.knowmore;
        const activeSecondColumnButton = element.dataset.secondcolumnbutton
            ? JSON.parse(element.dataset.secondcolumnbutton)
            : [];
        const activePage =
            element.dataset.activepage && element.dataset.activepage.length > 0
                ? element.dataset.activepage
                : '';

        setTimeout(() => {
            headerBackgroundImage.src = activeImageUrl;
            if (headerDescription) headerDescription.innerHTML = activeDescription;
            logoWrapper.textContent = '';
            if (activeLogo && activeLogo.length > 0) {
                const img = document.createElement('img');
                img.src = activeLogo;
                img.classList.add('header-menu__logo');
                img.setAttribute('alt', 'menu_logo');
                logoWrapper.appendChild(img);
            }

            createSecondMenu(activeSecondMenuData, activeColor, activePage);
            if (window.innerWidth < 993) {
                copySecondColumn();
            }
            createThirdColumnLink(
                activeThirdColumnLink,
                activeColor,
                knowMoreText
            );
            createSecondColumnButton(
                activeSecondColumnButton,
                activeColor,
                knowMoreText
            );

            headerMenu.classList.remove("-blocked");
        }, 310);
    };

    // Funkcja odpowiadająca za zmienianie aktywnego itemu

    const activeMenuItemHandler = element => {
        const activeColor = element.dataset.bgcolor;

        document
            .querySelector('.js-header-menu-item.active')
            .classList.remove('active');
        element.classList.add('active');

        removeBgColorClass(headerBackgroundColor);
        // removeBgColorClass(hamburgerWrapper);
        // hamburgerWrapper.classList.add(`bg-${activeColor}`);

        headerBackgroundColor.classList.remove('mobile-animation');
        backgroundImageWrapper.classList.remove('mobile-animation');
        headerBackgroundColor.classList.add(`bg-${activeColor}`);
        setTimeout(() => {
            headerBackgroundColor.classList.add('mobile-animation');
            backgroundImageWrapper.classList.add('mobile-animation');
        }, 800);

        animationsHandler(animationsElementsArray, 'firstClass');

        changeContent(element, activeColor);
    };

    openMenuButton.addEventListener('click', () => {
        openMenu();
    });

    closeMenuButton.addEventListener('click', () => {
        closeMenu();
    });

    headerMenuItem.forEach(element => {
        element.addEventListener('click', e => {
            if (!e.target.classList.contains('active')) {
                activeMenuItemHandler(e.target);
            }
        });
    });

    // ####### Przerzucanie menu mobile / desktop oraz wywolanie funkcji

    const copySecondColumn = () => {
        const activeMenuItem = document.querySelector(
            '.js-header-menu-item.active'
        );
        const thirdColumnWrapper = document.querySelector(
            '.header-menu__third-column-wrapper'
        );

        activeMenuItem.after(thirdColumnWrapper);
    };

    const placeThirdColumnInCorePosition = () => {
        const corePositionWrapper = document.querySelector('.js-third-column');
        const thirdColumnWrapper = document.querySelector(
            '.header-menu__third-column-wrapper'
        );
        corePositionWrapper.textContent = '';
        corePositionWrapper.appendChild(thirdColumnWrapper);
    };

    if (window.innerWidth < 993) {
        copySecondColumn();
    }

    window.addEventListener('resize', () => {
        if (window.innerWidth < 993) {
            copySecondColumn();
        } else {
            placeThirdColumnInCorePosition();
        }
    });

    /// ############### Menu mobile
    /// ############### Menu przycisk do kontaktu

    headerScrollHander(headerTop);
    headerContactShowHandler();
    
    document.addEventListener('scroll', e => {
        headerScrollHander(headerTop);
        headerTopHandler(headerTop);
        showContactBtn(headerContactBtn);
    });
}
