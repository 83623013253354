export default class BlogsFilter {
    constructor(el, parent) {
        if (!this.set(el, parent)) return;
        this.init(); 
    }

    set(el, parent) {
        if (!el || !parent) return false;
        this.root = el;
        this.parent = parent;
        this.blogTabEls = this.root.querySelectorAll('[data-blog-tab-filter]');

        return true;
    }

    init() {
        this.setOptions();
    }

    setOptions() {
        if (!this.blogTabEls) return;

        this.onOptionClick = this.onOption.bind(this);
        this.blogTabEls.forEach(item => {
            item.addEventListener('click', event => this.onOptionClick(event));
        })
    }

    onOption(event) {
        const { currentTarget } = event;
        const value = currentTarget.getAttribute('data-blog-id');

        const customEvent = new CustomEvent("blog-filter-change", {
            detail: value
        });

        this.parent.dispatchEvent(customEvent);

        this.resetOptions();
        this.setActive(currentTarget);
    }

    resetOptions() {
        this.blogTabEls.forEach(item => item.classList.remove('active'));
    }

    setActive(item) {
        item.classList.add('active')
    }
}