import copyLink from "../utils/copyLink";

export default function single() {
    const copyElements = document.querySelectorAll('.js-copy-link');

    copyElements.forEach((el) => {
        el.addEventListener('click', (e) => {
            e.preventDefault();
            copyLink(el, 'link');
        })
    })
}