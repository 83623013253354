const mobileHeaderYOffset = 400;

export default function showContactBtn(headerContactBtn) {
    const scrollTopPosition = window.scrollY;

    if (scrollTopPosition > mobileHeaderYOffset) {
        headerContactBtn.classList.add('show');
    } else {
        headerContactBtn.classList.remove('show');
    }
}
