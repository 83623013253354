import header from './modules/header/header';
import postScrollList from './modules/sections/postScrollList';
import gallery from './modules/utils/gallery';
import gallerySections from './modules/sections/sectionGallery';
import latestPosts from './modules/post/latestPosts';
import single from './modules/post/single';
import toTop from './modules/utils/toTop';
import accordion from './modules/utils/accordion';
import Aos from 'aos';
import { handleEnvelopeClick } from './modules/footer/handleEnvelopeClick';
import headerSearch from './modules/header/headerSearch';

var documentReady = function () {
    header();
    postScrollList();
    gallery();
    gallerySections();
    latestPosts();
    single();
    toTop();
    accordion();
    handleEnvelopeClick();
    headerSearch();
    
    Aos.init({
        anchorPlacement: 'top-bottom',
        once: true,
    });
};

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}
