import Swiper from "swiper";
import { Navigation, Autoplay } from 'swiper/modules';

Swiper.use(Navigation, Autoplay);

class LatestPostsSection {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;
        this.root = el;
        this.swiper = this.root.querySelector('[data-post-latest-swiper]');
        this.prevEl = this.root.querySelector('[data-prev]');
        this.nextEl = this.root.querySelector('[data-next]');
        this.swiperInstance = null;

        this.activeIndex = 0;

        return true;
    }

    init() {
        this.setSwiper();
        this.setResize();
    }

    setSwiper() {   
        const { innerWidth } = window;

        if (innerWidth >= 992) {
            if (this.swiperInstance) {
                this.swiperInstance.destroy();
                this.swiperInstance = null;
            }
        } else {
            if (!this.swiperInstance) {
                this.swiperInstance = new Swiper(this.swiper, {
                    slidesPerView: 1,
                    loop: true,
                    navigation: {
                        prevEl: this.prevEl,
                        nextEl: this.nextEl
                    }
                });
            }
        }
    }

    setResize() {
        window.addEventListener('resize', this.onResize.bind(this));
    }

    onResize() {
        this.setSwiper();
    }
}

export default function latestPosts() {
    const sections = document.querySelectorAll('[data-post-latest]');
    sections.forEach(item => {
        new LatestPostsSection(item);
    });
}
