export default function accordion() {
    const accordions = document.querySelectorAll('.accordion');

    if (accordions.length < 1) return;

    accordions.forEach(element => {
        const accordionItems = element.querySelectorAll('.accordion__item');
        accordionItems.forEach((item) => {

            item.addEventListener('click', (e) => {
                e.preventDefault();
                const clickedItem = e.currentTarget;

                if (clickedItem.classList.contains('active')) {
                    clickedItem.classList.remove('active');
                } else {
                    const activeElement = element.querySelector('.active');
                    if (activeElement) {
                        activeElement.classList.remove('active');
                    }
                    clickedItem.classList.add('active');
                }
            })

        })
    });
}