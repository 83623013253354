import showContactBtn from "../header/headerContactBtnHandler";

export const handleEnvelopeClick = () => {
    const triggerEnvelope = document.querySelector(".js-trigger-envelope");
    if (!triggerEnvelope) return;
    const jsShowForm = document.querySelector(".js-show-form");
    if (!jsShowForm) return;

    triggerEnvelope.addEventListener("click", () => jsShowForm.click() );

    document.addEventListener("scroll" , () => {
        showContactBtn(triggerEnvelope);
    })
}