export default class BaseFilter {
    constructor(el, parent) {
        if (!this.set(el, parent)) return;
        this.init(); 
    }

    set(el, parent) {
        if (!el || !parent) return false;
        this.root = el;
        this.parent = parent;
        this.optionsEls = this.root.querySelectorAll('[data-base-filter-option]');

        return true;
    }

    init() {
        this.setOptions();
    }

    setOptions() {
        if (!this.optionsEls) return;

        this.onOptionClick = this.onOption.bind(this);
        this.optionsEls.forEach(item => {
            item.addEventListener('click', event => this.onOptionClick(event));
        })
    }

    onOption(event) {
        const { currentTarget } = event;
        const value = currentTarget.getAttribute('data-value');

        const customEvent = new CustomEvent("base-filter-change", {
            detail: value,
        });

        this.parent.dispatchEvent(customEvent);

        this.resetOptions();
        this.setActive(currentTarget);
    }

    resetOptions() {
        this.optionsEls.forEach(item => item.classList.remove('active'));
    }

    setActive(item) {
        item.classList.add('active')
    }

    removeListener() {
        this.onOptionClick = this.onOption.bind(this);
        this.optionsEls.forEach(item => {
            item.removeEventListener('click', event => this.onOptionClick(event));
        })
    }
}