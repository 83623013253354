const headerLanguages = document.querySelector('.header-languages');
const mobileHeaderYOffset = 400;
const headerSide = document.querySelector('.header-side');
let lastScrollTop = window.scrollY;

export default function headerScrollHandler(headerTop) {
    const scrollTopPosition = window.scrollY;
    if (scrollTopPosition < 121) {
        headerTop.classList.add('active-mobile');
        headerSide.classList.add('active-mobile');

        if (headerLanguages) headerLanguages.classList.add('active-mobile');
    } else if (
        scrollTopPosition > 120 &&
        scrollTopPosition < mobileHeaderYOffset
    ) {
        headerTop.classList.remove('active-mobile');
        if (headerLanguages) headerLanguages.classList.remove('active-mobile');
        headerSide.classList.remove('active-mobile');
        headerTop.classList.add('block-animation');
        headerSide.classList.add('block-animation');
    }
    if (
        scrollTopPosition > mobileHeaderYOffset &&
        scrollTopPosition >= lastScrollTop
    ) {
        headerTop.classList.remove('block-animation');
        headerSide.classList.remove('block-animation');
        headerTop.classList.remove('active-mobile');
        headerSide.classList.remove('active-mobile');
        headerTop.classList.add('scroll-mobile');
        if (headerLanguages) headerLanguages.classList.add('scroll-mobile');
        headerSide.classList.add('scroll-mobile');
    } else if (scrollTopPosition < lastScrollTop) {
        headerTop.classList.remove('scroll-mobile');
        if (headerLanguages) headerLanguages.classList.remove('scroll-mobile');
        headerSide.classList.remove('scroll-mobile');
    }
    lastScrollTop = scrollTopPosition <= 0 ? 0 : scrollTopPosition;
}
