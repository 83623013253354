export default function searchPagination() {
    var searchPaginationButtons = document.querySelectorAll('#searchPagination button'),
        searchResultsContainer = document.querySelector('.search__results'),
        searchPaginationContainer = document.getElementById('searchPagination'),
        searchValue = document.getElementById('searchValue').value,
        maxPages = document.querySelector('.search__pagination--last').getAttribute('data-page');
    searchPaginationButtons.forEach(button => {
        button.addEventListener( 'click', () => updateResults(button.getAttribute('data-page')));
    });
    
    function refreshPagination() {
        searchPaginationButtons = document.querySelectorAll('#searchPagination button'),
        searchResultsContainer = document.querySelector('.search__results'),
        searchPaginationContainer = document.getElementById('searchPagination'),
        searchValue = document.getElementById('searchValue').value,
        maxPages = document.querySelector('.search__pagination--last').getAttribute('data-page');
        searchPaginationButtons.forEach(button => {
            button.addEventListener( 'click', () => updateResults(button.getAttribute('data-page')));
        });
    }

    function updateResults(page) {
        let prevPageBtn = document.querySelector('#searchPagination .search__pagination--prev'),
            nextPageBtn = document.querySelector('#searchPagination .search__pagination--next');
        const paginationElements = document.querySelectorAll('.search__pagination--page');
    
        paginationElements.forEach(element => {
            element.classList.remove('current');
        });
    
        const targetElement = Array.from(paginationElements).find(element => 
            element.getAttribute('data-page') === page.toString()
        );
    
        if (targetElement) {
            targetElement.classList.add('current');
        }

        if ((parseInt(page) - 1 > 0) && prevPageBtn) prevPageBtn.dataset.page = parseInt(page) - 1;
        if ((parseInt(page) + 1 < maxPages) && nextPageBtn) nextPageBtn.dataset.page = parseInt(page) + 1;
        console.log(maxPages);
        
        const data = {
            'action' : 'search_posts_by_page',
            'page' : page,
            's' : searchValue
        }
        $.ajax({
            type: 'post',
            url: ajax.url,
            data: data,
            beforeSend: function () {
                $(searchResultsContainer).addClass('loader');
            },
            success: function (response) {
               $(searchResultsContainer).html(response.content_results);
               $(searchPaginationContainer).html(response.pagination);
               searchResultsContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
               $(searchResultsContainer).removeClass('loader');
               refreshPagination();
            },
        });
    }
}