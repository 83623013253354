export default function headerSearch() {
    const searchPopup = document.querySelector('.header__search'),
        openSearchBtn = document.getElementById('openSearchModal'),
        closeSearchBtn = document.getElementById('closeSearchModal');
    
    if (openSearchBtn && closeSearchBtn) {
        openSearchBtn.addEventListener('click', () => {
            searchPopup.classList.add('opened');
        });
        
        closeSearchBtn.addEventListener('click', () => {
            searchPopup.classList.remove('opened');
        });
    }
}