import Swiper from "swiper";
import { Navigation, Autoplay } from 'swiper/modules';

Swiper.use(Navigation, Autoplay);

class Gallery {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;
        this.root = el;
        this.swiper = this.root.querySelector('[data-gallery-swiper]');
        this.currentEl = this.root.querySelector('[data-gallery-current]');
        this.prevEl = this.root.querySelector('[data-prev]');
        this.nextEl = this.root.querySelector('[data-next]');
        this.swiperInstance = null;
        this.variant = this.root.dataset.variant;
        this.counter = this.root.querySelector('.section-gallery__counter');

        this.activeIndex = 0;

        return true;
    }

    init() {
        this.setSwiper();
        this.setResize();
    }

    setSwiper() {   
        const { innerWidth } = window;

        if (innerWidth >= 992) {
            if (this.swiperInstance) {
                this.swiperInstance.destroy();
                this.swiperInstance = null;
            }
        } else {
            if (!this.swiperInstance) {
                this.swiperInstance = new Swiper(this.swiper, {
                    slidesPerView: this.variant === 'alt' ? 1.25 : 1,
                    spaceBetween: this.variant === 'alt' ? 16 : 0,
                    loop: true,
                    navigation: {
                        prevEl: this.prevEl,
                        nextEl: this.nextEl
                    }
                });
    
                if (this.counter) {
                    this.swiperInstance.on('slideChange', function(swiper) {
                        let { realIndex } = swiper;
                        this.currentEl.innerHTML = realIndex + 1;
                    }.bind(this))
                }
            }
        }
    }

    setResize() {
        window.addEventListener('resize', this.onResize.bind(this));
    }

    onResize() {
        this.setSwiper();
    }
}

export default function gallerySections() {
    const galleries = document.querySelectorAll('[data-gallery]');
    galleries.forEach(item => {
        new Gallery(item);
    });
}
