export default class Pagination {
    constructor(el, parent) {
        if (!this.set(el, parent)) return;
        this.init();
    }

    set(el, parent) {
        if (!el || !parent) return false;

        this.root = el;
        this.parent = parent;
        this.settings = JSON.parse(el.getAttribute('data-settings'));
        this.numbers = this.root.querySelector('.pagination__numbers');
        this.prev = this.root.querySelector('.pagination__nav--prev');
        this.next = this.root.querySelector('.pagination__nav--next');
        this.first = this.root.querySelector('.pagination__nav--first');
        this.last = this.root.querySelector('.pagination__nav--last');
        this.featuredColor = this.root.dataset.color && this.root.dataset.color.length > 0 ? this.root.dataset.color : 'grey-grey';

        this.currentPage = 1;
        this.maxPages = this.settings.max_pages;

        return true;
    }

    init() {
        this.setItemsTemplate();
        this.setActiveItem();
        this.initNumbers();
        this.bindNav();
    }

    initNumbers() {
        if (!this.items) return;
        this.items.forEach(item => {
            item.addEventListener('click', event => this.onChange(event));
        });
    }

    bindNav() {
        if (!this.prev || !this.next || !this.first || !this.last) return;
        this.onPrevHandler = this.onPrev.bind(this);
        this.onNextHandler = this.onNext.bind(this);
        this.onFirstHandler = this.onFirst.bind(this);
        this.onLastHandler = this.onLast.bind(this);

        this.prev.addEventListener('click', this.onPrevHandler);
        this.next.addEventListener('click', this.onNextHandler);
        this.first.addEventListener('click', this.onFirstHandler);
        this.last.addEventListener('click', this.onLastHandler);
    }

    onChange(event) {
        const { currentTarget } = event;
        const index = currentTarget.getAttribute('data-item');
        if (parseInt(index) === parseInt(this.currentPage)) return;
        this.currentPage = index;
        this.sendChangeEvent(index);
        this.setActiveItem();
    }

    onFirst() {
        this.currentPage = 1;
        this.sendChangeEvent(this.currentPage);
        this.setActiveItem();
    }

    onLast() {
        this.currentPage = this.maxPages;
        this.sendChangeEvent(this.currentPage);
        this.setActiveItem();
    }

    onPrev() {
        let current = this.currentPage;
        current = current - 1;
        if (current < 1) current = 1;
        this.currentPage = current;
        this.sendChangeEvent(this.currentPage);
        this.setActiveItem();
    }

    onNext() {
        let current = this.currentPage;
        current = current + 1;
        if (current > this.maxPages) current = this.maxPages;
        this.currentPage = current;
        this.sendChangeEvent(this.currentPage);
        this.setActiveItem();
    }

    sendChangeEvent(index) {
        const event = new CustomEvent('pagination-change', {
            detail: parseInt(index),
        });

        this.parent.dispatchEvent(event);
    }

    setActiveItem() {
        this.resetItems();
        const active = this.root.querySelector(
            `[data-item="${this.currentPage}"]`
        );
        if (!active) return;

        active.classList.add('active');
        active.classList.add(`bg-${this.featuredColor}`);
    }

    resetItems() {
        if (!this.items) return;
        this.items.forEach(item => {
            item.classList.remove(`bg-${this.featuredColor}`);
            item.classList.remove('active');
        });
    }

    reset(currentPage, maxPages) {
        this.currentPage = parseInt(currentPage);
        this.maxPages = parseInt(maxPages);
        this.clearListeners();
        this.init();
    }

    setItemsTemplate() {
        if (this.maxPages === 1) {
            this.root.style.display = 'none';
            return;
        }

        const pageRange = this.pageRange(this.currentPage, this.maxPages);

        this.root.style.display = '';
        let template = ``;
        for (
            let i = pageRange.start;
            i <= pageRange.end;
            i++
        ) {
            template += `<div class="pagination__item bgh-${this.featuredColor}" data-item="${i}">${i}</div>`;
        }

        this.numbers.innerHTML = template;
        this.items = this.root.querySelectorAll('.pagination__item');
    }

    pageRange(page, pageCount){
        let start = page - 2,
            end = page + 2;
        
        if(end > pageCount){
            start -= (end - pageCount);
            end = pageCount;
        }
        if(start <= 0){
            end += ((start - 1) * (-1));
            start = 1;
        }
        
        end = end > pageCount ? pageCount: end;    
        return { start:start, end:end };
    }

    clearListeners() {
        this.prev.removeEventListener('click', this.onPrevHandler);
        this.next.removeEventListener('click', this.onNextHandler);
        this.first.removeEventListener('click', this.onFirstHandler);
        this.last.removeEventListener('click', this.onLastHandler);

        if (this.items) {
            this.items.forEach(item => {
                item.removeEventListener('click', this.onChange);
            });
        }
    }

    hidePagination() {
        this.root.style.display = 'none';
    }

    showPagination() {
        this.root.style.display = '';
    }
}
