export default function headerContactShowHandler() {
    const showHeaderContactButton = document.querySelector('.js-show-form');
    const hideHeaderContactButton = document.querySelector(
        '.js-close-header-contact'
    );
    const contactOverley = document.querySelector(".header-contact__overlay");

    const headerContact = document.querySelector('.header-contact');

    showHeaderContactButton.addEventListener('click', () => {
        headerContact.classList.add('show');
    });

    hideHeaderContactButton.addEventListener('click', () => {
        headerContact.classList.remove('show');
    });

    contactOverley.addEventListener('click', () => {
        headerContact.classList.remove('show');
    });
}
